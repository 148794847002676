import { Container, Grid } from '@chakra-ui/react';
import { Newsletter as Props } from '../../types';
import Image from '../Image';
import Teaser from '../Teaser';

const Newsletter = ({ title, lead, link }: Props) => (
  <Grid
    alignItems="center"
    as="section"
    position="relative"
    data-test-id="section-newsletter-teaser"
  >
    <Image src="/newsletter-bg.jpg" alt={title} layout="fill" />
    <Teaser
      as={Container}
      gridRow="1"
      gridColumn="1"
      maxW="container.lg"
      variant="white"
      zIndex="1"
      heading={{ title, importance: 'h2' }}
      textStyle="md"
      text={lead}
      buttons={[{ link, size: 'large' }]}
      py={['12.5%', '5%', '4.5%']}
      innerProps={{
        maxW: '924px', // Matches with footer content align
        mx: 'auto',
      }}
    />
  </Grid>
);

export default Newsletter;
